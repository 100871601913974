import React, { useState, useRef } from "react"
import styled from "styled-components"
import { FaChevronDown, FaTimes } from "react-icons/fa"
import { colors, H3DarkBlue, B1DarkBlue } from "../../styles/helpers"

const FaqItem = ({ question, answer }) => {
  const [setActive, setActiveState] = useState("")
  const [setHeight, setHeightState] = useState("0px")
  const content = useRef(null)

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "")
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    )
  }
  return (
    <FaqItemStyled>
      <button
        className={`faqSingle__question ${setActive}`}
        onClick={toggleAccordion}
      >
        <p>{question}</p>
        <span className="icon">
          {setActive === "active" ? <FaTimes /> : <FaChevronDown />}
        </span>
      </button>
      <div
        className="faqSingle__answer"
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
      >
        <div dangerouslySetInnerHTML={{ __html: answer }} />
      </div>
    </FaqItemStyled>
  )
}

const FaqItemStyled = styled.div`
  margin: 0;
  overflow: hidden;
  border-bottom: 0.2rem solid ${colors.colorAccent};

  @media (min-width: 768px) {
  }

  .faqSingle__question {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2rem 0;
    background: ${colors.white};
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;

    p {
      ${H3DarkBlue}
      margin: 0;
      padding-right: 4rem;
      font-weight: normal;
    }
  }

  .icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    margin: auto;
    transition: transform 0.6s ease;
    color: ${colors.colorSecondary};

    svg {
      display: block;
      width: 4rem;
      height: 4rem;
      transition: transform 0.6s ease;
      transform-origin: center;
    }
  }

  .faqSingle__answer {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;

    p {
      ${B1DarkBlue};
    }

    a {
      ${B1DarkBlue};
      text-decoration: underline;

      &:hover {
        color: ${colors.colorSecondary};
      }
    }
  }
`

export default FaqItem
