import React from "react"
import styled from "styled-components"
import {
  B1DarkBlue,
  colors,
  H1DarkBlue,
  medWrapper,
} from "../../styles/helpers"

const TitleIntro = ({ data }) => {
  return (
    <StyledSection>
      <div className="wrapper">
        <div className="title">
          <h1>{data.titleIntroTitle}</h1>
        </div>
        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: data.titleIntroContent }} />
        </div>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  padding: 3.5rem 0;

  .wrapper {
    ${medWrapper}
  }

  .title {
    width: calc(100%);
    text-align: center;
    margin: 0 0 2rem;
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid ${colors.colorAccent};

    @media (min-width: 768px) {
      width: calc(100% - 12rem);
      margin: 0 6rem 2rem;
    }

    @media (min-width: 1025px) {
      width: calc(100% - 16rem);
      margin: 0 8rem 2rem;
    }

    h1 {
      ${H1DarkBlue};
      margin: 0;
    }
  }

  .content {
    width: calc(100% - 4rem);
    margin: 0 2rem;
    text-align: center;

    @media (min-width: 768px) {
      width: calc(100% - 12rem);
      margin: 0 6rem;
    }

    @media (min-width: 1025px) {
      width: calc(100% - 16rem);
      margin: 0 8rem;
    }

    p {
      ${B1DarkBlue};
    }
  }
`

export default TitleIntro
