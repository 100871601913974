import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import TitleIntro from "../components/faqs/TitleIntro"
import FaqsList from "../components/faqs/FaqsList"

const faqs = props => {
  const titleIntro = props?.data?.titleIntro?.template?.faqs
  const faqsList = props?.data?.faqsList?.template?.faqs

  return (
    <Layout>
      <Seo title="FAQs" />
      <TitleIntro data={titleIntro} />
      <FaqsList data={faqsList} />
    </Layout>
  )
}

export const faqsQuery = graphql`
  query faqsTempPage($id: String!) {
    # seoInfo: wpPage(id: { eq: $id }) {
    #   seoFields {
    #     swbThemeDescription
    #     swbThemeMetaTitle
    #     swbThemeImage {
    #       localFile {
    #         relativePath
    #       }
    #     }
    #   }
    # }

    titleIntro: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_FAQs {
          faqs {
            titleIntroTitle
            titleIntroContent
          }
        }
      }
    }

    faqsList: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_FAQs {
          faqs {
            faqsSections {
              faqsTitle
              faqsFaqs {
                answer
                question
              }
            }
          }
        }
      }
    }
  }
`

export default faqs
