import React, { useState } from "react"
import styled from "styled-components"
import FaqItem from "./FaqItem"
import { B1Brown, colors, medWrapper } from "../../styles/helpers"

const FaqsList = ({ data }) => {
  const [activeTab, setActiveTab] = useState(1)
  const handleChangeActiveTab = index => {
    setActiveTab(index)
  }
  const faqSections = data.faqsSections ? data.faqsSections : []

  return (
    <StyledSection>
      <div className="wrapper">
        <div className="title">
          {faqSections.map((section, index) => {
            return (
              <>
                <ButtonStyled
                  activetab={index + 1 === activeTab}
                  index={index}
                  onClick={() => {
                    handleChangeActiveTab(index + 1)
                  }}
                  type="button"
                >
                  {section.faqsTitle}
                </ButtonStyled>
                {index + 1 !== faqSections.length ? <span> | </span> : null}
              </>
            )
          })}
        </div>
        <div className="faqsContainer">
          {faqSections.map((section, index) => {
            return (
              <ContentWrapper index={index} activetab={index + 1 === activeTab}>
                {section.faqsFaqs.map((faq, index) => {
                  return (
                    <FaqItem
                      key={index}
                      question={faq.question}
                      answer={faq.answer}
                    />
                  )
                })}
              </ContentWrapper>
            )
          })}
        </div>
        <div className="bgGraphic" />
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.div`
  position: relative;
  padding-bottom: 7.5rem;
  z-index: 250;

  .wrapper {
    ${medWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    button {
      ${B1Brown};
      display: inline-block;
      border: none;
      background-color: transparent;
      text-transform: uppercase;
      cursor: pointer;
    }

    span {
      ${B1Brown};
    }
  }

  .faqsContainer {
    width: 100%;
    padding: 1.5rem 0;

    @media (min-width: 768px) {
      width: calc(100% - 16rem);
    }
  }
`

const ButtonStyled = styled.button`
  color: ${props =>
    props.activetab ? colors.colorAccent : colors.colorSecondary} !important;
`

const ContentWrapper = styled.div`
  display: ${props => (props.activetab ? "block" : "none")};
  width: 100%;
`

export default FaqsList
